import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBlock } from 'reactstrap';
import {Table, Th, Thead} from 'reactable';

const _ = require('lodash');

const ViewHeader = () => (
    <div className="view-header">
        <header className="title text-white">
            <h1 className="h4 text-uppercase"> <i className="fas fa-list"></i> 產品列表</h1>
            {/*<p className="mb-0">List of products</p>*/}
        </header>
    </div>
);

const ViewContent = ({children}) => (
    <div className="view-content view-components">
        {children}
    </div>
);

export default class Dashboard extends Component {
    constructor() {
        super();
        this.state = {};
    }

    componentDidMount() {
        // TODO - pagination
        this.props.ProductsModel.scan().all().exec().then((products) => {
            const newProducts = _.chain(products)
                .sortBy(['created'])
                .map((p) => {
                    p.name = <Link to={`/products/${p.id}`}>{p.name}</Link>;
                    p.details = <Link to={`/products/${p.id}/details`}><i className="fas fa-info"></i> 詳細資訊 </Link>;
                    return p;
                })
                .value();
            const newState = _.assign({}, this.state, {tableData: newProducts});
            this.setState(newState);
        }).catch((e) => {
            console.error(e);
        });
    }

    render() {
        return (<div className="view">
            <ViewHeader/>
            <ViewContent>
                <Card className="mb-4">
                    <CardBlock className="table-responsive">
                        <h6 className="mb-4 text-uppercase">產品</h6>
                        <Table className="table" data={this.state.tableData} itemsPerPage={5} pageButtonLimit={5} previousPageLabel="<<" nextPageLabel=">>" sortable={[
                            {
                                column: 'name',
                                sortFunction: (a, b) => {
                                    return a.props.children.localeCompare(b.props.children);
                                }
                            },
                            'created',
                            'details',
                        ]} defaultSort={{column: 'created', direction: 'desc'}}>
                            <Thead>
                            <Th column="name"><span>名稱</span></Th>
                            <Th column="created"><span>建立時間</span></Th>
                            <Th column="details"><span>詳細資訊</span></Th>
                            </Thead>
                        </Table>
                        <div className="mb-5">
                            <Link to='/products/new'>
                                <Button color="primary" className="mb-2" ><i className="fas fa-plus"></i> 新增 </Button>{" "}
                            </Link>
                        </div>
                    </CardBlock>
                </Card>
            </ViewContent>
        </div>);
    }
}
