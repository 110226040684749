import React from "react";
import { Route, Redirect } from "react-router-dom";
import queryString from 'query-string';

export default ({ component: C, props: cProps, ...rest }) => {
    const values = queryString.parse(window.location.href);
    const redirect = values.redirect ? values.redirect : null;
    return (
        <Route
            {...rest}
            render={props =>
                !props.isAuthenticated
                    ? <C {...props} {...cProps} />
                    : <Redirect
                        to={redirect === "" || redirect === null ? "/" : redirect}
                    />}
        />
    );
};
