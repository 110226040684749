import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {Collapse} from 'reactstrap';


// icons
import IconDashboard from 'react-icons/lib/md/dashboard';
import IconWidgets from 'react-icons/lib/md/extension';
import IconUI from 'react-icons/lib/md/gradient';
import IconPages from 'react-icons/lib/md/filter-none';
import IconChart from 'react-icons/lib/md/landscape';
import IconTable from 'react-icons/lib/md/grid-on';
import IconForm from 'react-icons/lib/md/layers';
import IconDown from 'react-icons/lib/md/chevron-right';
import IconMail from 'react-icons/lib/md/email';
import ScrollArea from '../scrollbar';

import './style.css';

const logoUrl = "https://s3-ap-northeast-1.amazonaws.com/www.fastable.ai/img/fastable_logo_dark_transparent_bg.png";
const logoStyle = {
    maxWidth: "100%",
    maxHeight: "100%",
    marginRight: "0.2rem"
};
const NavHead = (props) => (
    <header className="nav-head">
        <Link to="/">
            <img
              src={ logoUrl }
              style={ logoStyle }
            />
            fastable.ai
        </Link>
        {/*
        <div className={`toggle-dot ${props.mini ? 'active': ''}`} onClick={props.toggleNav}></div>
        */}
    </header>
);

class NavList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 0
        }
    }
    handleClick = (index, e) => {
        let c = e.currentTarget.className;
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            selected: (c.indexOf('selected') >= 0) ? '' : index
        })
    }
    handleOpen = (index, e) => {
        e.stopPropagation();
        this.setState({
            selected: index
        })
    }


    render() {
        return <ScrollArea className="nav-list-container" horizontal={false} verticalScrollbarStyle={{width: '4px', marginLeft: '10px'}}>
            <ul className="list-unstyled nav-list clearfix">
                <li><div className="nav-list-title">選單</div></li>
                <li onClick={this.handleClick.bind(this, 0)} className={(this.state.selected === 0) ? 'selected': ''}>
                    <NavLink to="/" activeClassName="active">
                        <IconDashboard size="18" color="#2962FF" className="icon-dashboard"/>
                        <span className="name">產品列表</span>
                    </NavLink>
                </li>
            </ul>
            {/* end scroll-area */}
        </ScrollArea>
    }
}




export default (props) => (
    <nav className={`site-nav ${props.mini ? 'mini' : ''}`}>
        <NavHead {...props}/>
        <NavList/>

    </nav>
);
