const AWS_REGION = "ap-northeast-1";

const API_GATEWAY_ENDPOINT_URL = "https://1lewy796c3.execute-api.ap-northeast-1.amazonaws.com";
const API_GATEWAY_ENDPOINT_NAME = "FastableLabelingToolApi";

const INTERNAL_INFO = Object.freeze({
    cognitoUserPoolId: "ap-northeast-1_aYuTjdFuk",
    cognitoAppClientId: "15v771uvrtkqiksu4j688rfe9j",
});

const CUSTOMER_INFO = Object.freeze({
    "internal": {
        cognitoUserPoolId: "ap-northeast-1_XeHR6z756",
        cognitoIdentityPoolId: "ap-northeast-1:1ad986d9-63fe-48ae-827b-cf4eae949d97",
        cognitoAppClientId: "1paek0eoo40nbs3oqqvek39n49",
        uploadBucket: "fastable-labeling-tool-uploads",
        tablePrefix: "FastableLabelingTool",
    },
    "ttfco": {
        cognitoUserPoolId: "ap-northeast-1_LOfFqaGEs",
        cognitoIdentityPoolId: "ap-northeast-1:5b212d0c-1809-4c1e-82a3-5304a7b4e3d9",
        cognitoAppClientId: "714gnrde0uln6t7ccrffrhblf8",
        uploadBucket: "fastable-ttfco-labeling-tool-uploads",
        tablePrefix: "FastableTtfcoLabelingTool",
    },
    "dcr-wood": {
        cognitoUserPoolId: "ap-northeast-1_WE1f4IpNc",
        cognitoIdentityPoolId: "ap-northeast-1:bf690b8d-9884-40ae-b77b-aa7b0e597483",
        cognitoAppClientId: "347k3fq1lhis9ua2gs5dvaqudl",
        uploadBucket: "fastable-dcr-wood-labeling-tool-uploads",
        tablePrefix: "FastableDCRWoodLabelingTool",
        predefinedLabels: ["單片脫膠", "複貼雜質", "單片反折", "滲膠", "薄片開裂", "起泡", "壓傷",
            "劃傷", "膠線", "膠疤", "刀印", "皺紋", "面板油汙", "藍汙", "中板不平", "基板滲透", "其它"],
        onlyPredefinedLabels: true,
        resolution:0.12
    },
    "hc": {
        cognitoUserPoolId: "ap-northeast-1_YNwpQTN6h",
        cognitoIdentityPoolId: "ap-northeast-1:d701ec72-8c3f-49f4-a538-d5f6e447b074",
        cognitoAppClientId: "l6su2hkun0hard5uqubub8sj0",
        uploadBucket: "fastable-hc-labeling-tool-uploads",
        tablePrefix: "FastableHCLabelingTool",
        predefinedLabels: ["毛邊", "拐紗", "破洞", "缺角", "針邊不平", "帶面毛球", "異色邊", "異物", "脫線", "摺痕",
            "漏織", "緯紗外漏", "緯紗粗細", "斷紗", "斷緯"],
        onlyPredefinedLabels: true,
        resolution:0.08
    },
});

module.exports = Object.freeze({
    AWS_REGION,

    API_GATEWAY_ENDPOINT_NAME,
    API_GATEWAY_ENDPOINT_URL,

    CUSTOMER_INFO,
    INTERNAL_INFO
});