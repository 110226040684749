import Amplify, { Auth } from "aws-amplify";

import React from 'react';
import Nav from './shared/components/nav';
import SiteHead from './shared/components/header';
import Footer from './shared/components/footer';

import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import AppliedRoute from "./AppliedRoute";
import AuthenticatedRoute from "./AuthenticatedRoute";
import UnauthenticatedRoute from "./UnauthenticatedRoute";

import Dashboard from './views/dashboard';
import SignIn from './views/pages/signin';
import Page404 from './views/pages/404';
import FormGeneral from './views/forms/general';
import Products from './views/products';
import Images from './views/images';
import Annotation from './views/annotation';

import './app.css';

const Constants = require('./shared/Constants');
const Utils = require('./shared/Utils');

const getModels = require('./shared/Models');

const _ = require('lodash');

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navMini: false,
            isAuthenticated: false,
            isAuthenticating: true
        }
    }

    toggleNav = (e) => {
        e.preventDefault();
        this.setState({navMini: !this.state.navMini});
    }
    hideNav = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({navMini: false})
    }

    // set auth flag + dynamoose models
    userHasAuthenticated = async (authenticated) => {
        let newState = {
            isAuthenticated: authenticated
        };
        if (authenticated) {
            const creds = await Auth.currentCredentials();

            const models = getModels({
                accessKeyId: creds.accessKeyId,
                secretAccessKey: creds.secretAccessKey,
                sessionToken: creds.sessionToken,
                region: Constants.AWS_REGION
            });

            newState = _.assign({}, newState, models);
        }

        this.setState(newState);
    }

    configureAmplify = () => {
        const {
            cognitoUserPoolId,
            cognitoIdentityPoolId,
            cognitoAppClientId,
            uploadBucket
        } = Utils.getCustomerInfo();

        Amplify.configure({
            Auth: {
                mandatorySignIn: true,
                region: Constants.AWS_REGION,
                userPoolId: cognitoUserPoolId,
                identityPoolId: cognitoIdentityPoolId,
                userPoolWebClientId: cognitoAppClientId
            },
            Storage: {
                region: Constants.AWS_REGION,
                bucket: uploadBucket,
                identityPoolId: cognitoIdentityPoolId
            },
            API: {
                endpoints: [
                    {
                        name: Constants.API_GATEWAY_ENDPOINT_NAME,
                        endpoint: Constants.API_GATEWAY_ENDPOINT_URL,
                        region: Constants.AWS_REGION
                    },
                ]
            }
        });
    }

    async componentDidMount() {
        this.configureAmplify();

        try {
            await Auth.currentSession();
            await this.userHasAuthenticated(true);
        } catch (e) {
            // no current user
            console.warn("No current user");
        }
        this.setState({isAuthenticating: false});
    }

    render() {
        const {
            navMini,
            isAuthenticated,
            AccountsModel,
            ProductsModel,
            DatasetsModel,
            ImagesModel,
            AnnotationsModel,
            CommentsModel
        } = this.state;

        const childProps = {
            userHasAuthenticated: this.userHasAuthenticated,
            isAuthenticated,
            AccountsModel,
            ProductsModel,
            DatasetsModel,
            ImagesModel,
            AnnotationsModel,
            CommentsModel
        };

        return !this.state.isAuthenticating && (
            <div className="app-wrapper">
                <Router>
                    <Nav mini={navMini} toggleNav={this.toggleNav}/>
                    <div className={`content-container ${navMini ? 'full' : ''}`}>
                        {/* dropshadow for mobile nav triggering */}
                        <div className="menu-dropshadow" style={{display: (navMini ? 'block': 'none')}} onClick={this.hideNav}></div>
                        <SiteHead toggleNav={this.toggleNav} childProps={childProps} history={this.props.history} />

                        {/* Routes */}
                        <AppliedRoute exact path="/" component={() => <Redirect to="/products" />}/>
                        <Switch>
                            <AuthenticatedRoute exact path='/products' props={childProps} component={Dashboard} />
                            <AuthenticatedRoute exact path='/products/new' props={childProps} component={FormGeneral} />
                            <AuthenticatedRoute exact path='/products/:productId' props={childProps} component={Products} />
                            <AuthenticatedRoute exact path='/products/:productId/details' props={childProps} component={FormGeneral} />
                            <AuthenticatedRoute exact path='/products/:productId/datasets/:datasetId/images' props={childProps} component={Images} />
                            <AuthenticatedRoute exact path='/products/:productId/datasets/:datasetId/images/:imgId' props={childProps} component={Annotation} />
                            <UnauthenticatedRoute exact path='/signin' props={childProps} component={SignIn}/>
                            <UnauthenticatedRoute component={Page404} />
                        </Switch>
                    </div>
                    <Footer />
                </Router>
            </div>
        )
    }
}
