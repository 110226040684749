import React, { Component } from 'react';



class Footer extends Component {
    render() {
        return (
      <div className="footer-copyright text-center py-3">
          Copyright &copy; 2017 - {new Date().getFullYear()} 
          <a href="https://www.fastable.ai/" target="_blank"> <span> Kapito Inc. </span></a> 
          All Rights Reserved.
      </div>
        );
    }
}

export default Footer;
