const Utils = require('./Utils');

const dynamoose = require('dynamoose');

const getModels = (creds) => {
    if (creds)
        dynamoose.AWS.config.update(creds);

    const {
        tablePrefix
    } = Utils.getCustomerInfo();

    const accountsTable     = `${tablePrefix}Accounts`;
    const productsTable     = `${tablePrefix}Products`;
    const datasetsTable     = `${tablePrefix}Datasets`;
    const annotationsTable  = `${tablePrefix}Annotations`;
    const imagesTable       = `${tablePrefix}Images`;
    const commentsTable     = `${tablePrefix}Comments`;

    const AccountsModel = dynamoose.model(
        accountsTable,
        { id: String, companyName: String, contactName: String, contactPhone: String, contactEmail: String, created: Date }
    );

    const ProductsModel = dynamoose.model(
        productsTable,
        { id: String, accountId: String, name: String, inferenceSpeed: Number, estimatedNumberOfProductionLines: String, expectedOverkillRate: String, notes: String, expectedUnderkillRate: String, created: Date, companyName: String, contactName: String, contactPhone: String, contactEmail: String }
    );

    const DatasetsModel = dynamoose.model(
        datasetsTable,
        { id: String, productId: String, name: String, status: String, uploaded: Date, numImages: Number, numDefectTypes: Number }
    );

    const ImagesModel = dynamoose.model(
        imagesTable,
        { id: String, datasetId: String, bucket: String, prefix: String, filePath: String, fullPath: String, size: Number, zipFileName: String, url: String, created: Date }
    );

    const AnnotationsModel = dynamoose.model(
        annotationsTable,
        { id: String, productId: String, datasetId: String, imgId: String, data: String, geometry: String, created: Date }
    );

    // TODO - rename CommentsModel to something more general
    const CommentsModel = dynamoose.model(
        commentsTable,
        { id: String, text: String, skipped: Boolean, created: Date }
    );

    return Object.freeze({
        AccountsModel,
        ProductsModel,
        DatasetsModel,
        ImagesModel,
        AnnotationsModel,
        CommentsModel
    });
};


module.exports = getModels;
