import React, { Component } from 'react';
import {
    Card, CardBlock
} from 'reactstrap';
import { Link } from 'react-router-dom';
import {Table, Th, Thead} from 'reactable';
import Dropzone from 'react-dropzone';
import { Line } from 'rc-progress';

import {API, Storage} from "aws-amplify";

import './style.css';

// UUID v4 (random)
const UUID = require('uuid/v4');

const _ = require('lodash');

const Promise = require("bluebird");

const Constants = require('../../shared/Constants');
const Utils = require('../../shared/Utils');

class ViewHeader extends Component {
    render() {
        return (
            <div className="view-header">
                <header className="title text-white">
                    <div className="inline-breadcrumb-item inline-breadcrumb-last">{`${this.props.productName || ''}`}</div>
                </header>
            </div>
        );
    }
}

const ViewContent = ({children}) => (
    <div className="view-content view-components">
        {children}
    </div>
);

const unzipInS3 = (file, uuid, productId) => {
    const customerInfo = Utils.getCustomerInfo();

    API.get(Constants.API_GATEWAY_ENDPOINT_NAME, '/test', {
        'queryStringParameters': {
            'S3_BUCKET': customerInfo.uploadBucket,
            'tablePrefix': customerInfo.tablePrefix,
            'file-name': file.name,
            'productId': productId,
            'uuid': uuid,
        }
    }).then((e) => {
        console.log(e);
    }).catch((e) => {
        console.error(e);
        alert('無法解壓縮檔案。\nCould not unzip file!');
    });

    alert('正在解壓縮檔案，稍後請重新整理此頁面。\nUnzipping file in database. Please refresh the page in a moment.');
};

async function directS3Upload(file, productId, _this) {
    const uuid = UUID();
    const key = `${uuid}/${file.name}`;
    // https://github.com/aws-amplify/amplify-js/pull/648
    return Storage.put(
        key,
        file,
        {
            level: 'public',
            customPrefix: {
                public: '',
            },
            contentType: file.type,
            progressCallback(progress) {
                const percentComplete = (progress.loaded / progress.total) * 100;
                console.log(`Uploaded: ${progress.loaded}/${progress.total} = ${percentComplete}%`);
                _this.setState({
                    progress: percentComplete
                });
            }
        }
    ).then((res) => {
        console.log(`uploaded`, res);
        alert('檔案上傳完成。\nThe file has been uploaded successfully.');
        unzipInS3(file, uuid, productId);
    }).catch((e) => {
        console.error("Could not upload file!", e);
        alert('無法上傳檔案! \nCould not upload file!');
    });
};

class Accept extends React.Component {
  constructor() {
    super();
    this.state = {
      accepted: [],
      rejected: [],
      progress: 0
    }
  }

  render() {
    return (
      <section>
          <Dropzone className="dropzone"
            accept="application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip"
            onDrop={(accepted, rejected) => {
              if (_.size(rejected) > 0) {
                  alert('只接受 *.zip 檔案格式。請再試一次，並確定上傳的是有效檔案。');
                  return;
              }

              this.setState(_.assign({}, this.state, {
                  accepted,
                  rejected,
                  progress: 0
              }));

              _.each(accepted, (file) => {
                  // getSignedRequest(file, this.props.productId, this);
                  directS3Upload(file, this.props.productId, this);
              });
            }}
          >
            <p className="dropzone-message">拖放圖片壓縮檔(.zip)到此處</p>
            <p className="dropzone-message">目前只接受 *.zip 檔案格式</p>
            <br />
            <p>上傳進度 ({Math.floor(this.state.progress) + '%'})</p>
            <Line percent={this.state.progress} strokeWidth="1.0" strokeColor="#00c853" strokeLinecap="square" />
          </Dropzone>
{/*
        <aside>
          <h2>Accepted files</h2>
          <ul>
            {
              _.map(this.state.accepted, (f) => {
                  return <li key={f.name}>{f.name} - {f.size} bytes</li>
              })
            }
          </ul>
          <h2>Rejected files</h2>
          <ul>
            {
              _.map(this.state.rejected, (f) => {
                  return <li key={f.name}>{f.name} - {f.size} bytes</li>
              })
            }
          </ul>
        </aside>
*/}
      </section>
    );
  }
}

export default class Products extends Component {
    constructor() {
        super();
        this.state = {};
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            // fetch or other component tasks necessary for rendering
            this.fetch();
        }
    }

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        const {productId} = this.props.match.params;

        this.props.ProductsModel.get(productId).then((product) => {
            this.setState(_.assign({}, this.state, {productName: product.name}));
        }).catch((e) => {
            console.error(e);
        });

        // TODO - pagination
        this.props.DatasetsModel.scan({productId}).all().exec().then((datasets) => {
            const newDatasets = _.chain(datasets)
                .sortBy(['name'])
                .map((d) => {
                    d.name = <Link to={`/products/${productId}/datasets/${d.id}/images`}>{d.name}</Link>;
                    return d;
                })
                .value();

            // TODO - inefficient
            Promise.map(newDatasets, (dataset) => {
                return this.props.AnnotationsModel.scan({
                    productId,
                    datasetId: dataset.id,
                }).all().exec().then((annotations) => {
                    const numDefectTypes = _.chain(annotations)
                        .map('data')
                        .map(JSON.parse)
                        .map('text')
                        .sortBy()
                        .sortedUniq()
                        .size()
                        .value();

                    dataset.numDefectTypes = numDefectTypes;

                    return dataset;
                }).catch((e) => {
                    console.error(e);
                });
            }).then((finalDatasets) => {
                const newState = _.assign({}, this.state, {tableData: finalDatasets});
                this.setState(newState);
            });
        }).catch((e) => {
            console.error(e);
        });
    }

    render() {
        const {productId} = this.props.match.params;

        return (<div className="view">
            <ViewHeader productName={this.state.productName}/>
            <ViewContent>
                <Card className="mb-4">
                    <CardBlock className="table-responsive">
                        <h6 className="mb-4 text-uppercase">資料集</h6>
                        <Table className="table" data={this.state.tableData} itemsPerPage={5} pageButtonLimit={5} previousPageLabel="<<" nextPageLabel=">>" sortable={[
                            {
                                column: 'name',
                                sortFunction: (a, b) => {
                                    return a.props.children.localeCompare(b.props.children);
                                }
                            },
                            'numImages',
                            'numDefectTypes',
                            'uploaded',
                            'status',
                        ]} defaultSort={{column: 'uploaded', direction: 'desc'}}>
                            <Thead>
                                <Th column="name"><span>名稱</span></Th>
                                <Th column="numImages"><span>圖片數目</span></Th>
                                <Th column="numDefectTypes"><span>瑕疵類別數目</span></Th>
                                <Th column="uploaded"><span>上傳時間</span></Th>
                                <Th column="status"><span>狀態</span></Th>
                            </Thead>
                        </Table>
                    </CardBlock>
                </Card>
                <Card className="mb-4">
                    <CardBlock className="table-responsive">
                        <h6 className="mb-4 text-uppercase"><i className="fas fa-upload"></i> 上傳檔案</h6>
                        <Accept productId={productId} />
                    </CardBlock>
                </Card>
            </ViewContent>
        </div>)
    }
}