import React, { Component } from 'react';
import {
    Card, CardBlock
} from 'reactstrap';
import { Link } from 'react-router-dom';
import {Table, Th, Thead} from 'reactable';

import './style.css';


const _ = require('lodash');

const Promise = require("bluebird");

class ViewHeader extends Component {
    render() {
        return (
            <div className="view-header">
                <header className="title text-white">
                    <Link to={`/products/${this.props.productId}`} style={{color: 'white'}}>
                        <div className="inline-breadcrumb-item">{`${this.props.productName || ''}`}</div>
                    </Link> 
                    <div className="inline-breadcrumb-item"> &gt; </div>
                    <div className="inline-breadcrumb-item inline-breadcrumb-last">{`${this.props.zipFileName || ''}`}</div>
                    {/*<p className="mb-0">Product details</p>*/}
                </header>
            </div>
        );
    }
}

const ViewContent = ({children}) => (
    <div className="view-content view-components">
        {children}
    </div>
);

export default class Images extends Component {
    constructor() {
        super();
        this.state = {};
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            // fetch or other component tasks necessary for rendering
            this.fetch();
        }
    }

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        const {productId, datasetId} = this.props.match.params;

        this.props.ProductsModel.get(productId).then((product) => {
            this.setState(_.assign({}, this.state, {productName: product.name}));
        }).catch((e) => {
            console.error(e);
        });

        // TODO - pagination
        this.props.ImagesModel.scan({datasetId}).all().exec().then((images) => {
            const newImages = _.chain(images)
                .sortBy(['filePath'])
                .map((image, i) => {
                    image.filePath = <Link to={`/products/${productId}/datasets/${datasetId}/images/${i}`}>{image.filePath}</Link>;
                    return image;
                })
                .value();

            // TODO - inefficient
            Promise.map(newImages, (image) => {
                return this.props.AnnotationsModel.scan('imgId').eq(image.id).count().exec().then((ret) => {
                    image.status = ret>0 ? '已標籤' : '標籤中';
                    return image;
                }).catch((e) => {
                    console.error(e);
                });
            }).then((finalImages) => {
                const newState = _.assign({}, this.state, {tableData: finalImages, zipFileName: finalImages[0].zipFileName});
                this.setState(newState);
            });
        }).catch((e) => {
            console.error(e);
        });
    }

    render() {
        return (<div className="view">
            <ViewHeader productId={this.props.match.params.productId} productName={this.state.productName} zipFileName={this.state.zipFileName}/>
            <ViewContent>
                <Card className="mb-4">
                    <CardBlock className="table-responsive">
                        <h6 className="mb-4 text-uppercase"><i className="far fa-images"></i> 圖片列表 </h6>
                        <Table className="table" data={this.state.tableData} itemsPerPage={10} pageButtonLimit={5} previousPageLabel="<<" nextPageLabel=">>" sortable={[
                            {
                                column: 'filePath',
                                sortFunction: (a, b) => {
                                    return a.props.children.localeCompare(b.props.children);
                                }
                            },
                            'status',
                        ]} defaultSort={{column: 'filePath', direction: 'asc'}}>
                            <Thead>
                                <Th column="filePath"><span>名稱</span></Th>
                                <Th column="status"><span>狀態</span></Th>
                            </Thead>
                        </Table>
                    </CardBlock>
                </Card>
            </ViewContent>
        </div>)
    }
}