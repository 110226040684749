import React, { Component } from 'react';
import {
    Card, CardBlock, Col,
    Button, FormGroup, Label
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import {Link} from "react-router-dom";

// UUID v4 (random)
const UUID = require('uuid/v4');

const _ = require('lodash');

const ViewHeader = () => (
    <div className="view-header">
        <header className="title text-white">
            <h1 className="h4 text-uppercase">新增產品</h1>
        </header>
    </div>
);

const ViewContent = ({children}) => (
    <div className="view-content view-components">
        {children}
    </div>
);

class FormInputs extends Component {
    state = {};

    onValidSubmit(event, values) {
        // this.setState({values});

        const {productName} = values;
        delete values.productName;

        const newProduct = _.assign({}, values, {
            name: productName,
            created: (new Date).getTime()
        });

        let productId, newRow;
        if (this.props && this.props.match && this.props.match.params)
            productId = this.props.match.params.productId;

        if (!productId) {
            newProduct.id = UUID();
        } else {
            newProduct.id = productId;
        }
        newRow = new this.props.ProductsModel(newProduct);
        newRow.save();

        this.props.router.push('/products');

        event.preventDefault();
    }

    handleInvalidSubmit(event, errors, values) {
        // this.setState({errors, values});
        this.setState({errors});
    }

    componentDidMount() {
        const { productId } = this.props.match.params;
        if (productId) {
            this.props.ProductsModel.get(productId).then((product) => {
                product.productName = product.name;
                this.setState(_.assign({}, this.state, {
                    defaultValues: product
                }));
            }).catch((e) => {
                console.error(e);
            });
        } else {
            // TODO - hardcoded acct id
            // const {accountId} = this.props;
            const accountId = '1';
            this.props.AccountsModel.get(accountId).then((account) => {
                this.setState(_.assign({}, this.state, {
                    defaultValues: account
                }));
            }).catch((e) => {
                console.error(e);
            });
        }
    }

    render() {
        if (!this.state.defaultValues) {
            return (
                <div className="view">
                    <ViewHeader/>
                    <ViewContent/>
                </div>
            );
        } else {
            return (
                <div className="view">
                    <ViewHeader/>
                    <ViewContent>
                        <Card className="mb-4">
                            <CardBlock>
                                <h6 className="mb-4 text-uppercase">請填入產品資訊</h6>
                                    <AvForm onValidSubmit={this.onValidSubmit.bind(this)} onInvalidSubmit={this.handleInvalidSubmit.bind(this)} model={this.state.defaultValues}>
                                        <FormGroup row>
                                            <Label for="companyName" sm={3}><i className="far fa-building"></i> 公司名稱</Label>
                                            <Col sm={9}>
                                                <AvField name="companyName" type="text" id="companyName" validate={{
                                                    required: {value: true, errorMessage: 'Please enter company name'},
                                                }}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="contactName" sm={3}><i className="far fa-user"></i> 聯絡人名稱</Label>
                                            <Col sm={9}>
                                            <AvField name="contactName" type="text" id="contactName" validate={{
                                                required: {value: true, errorMessage: 'Please enter contact name'},
                                            }}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="contactPhone" sm={3}><i className="fas fa-phone"></i> 聯絡人電話</Label>
                                            <Col sm={9}>
                                                <AvField name="contactPhone" type="text" id="contactPhone" validate={{
                                                    required: {value: true, errorMessage: 'Please enter phone number'},
                                                }}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="contactEmail" sm={3}><i className="far fa-envelope"></i> 聯絡人電子郵件信箱</Label>
                                            <Col sm={9}>
                                                <AvField name="contactEmail" type="text" id="contactEmail" validate={{
                                                    required: {value: true, errorMessage: 'Please enter email address'},
                                                    email: {value: true, errorMessage: 'Invalid email address'},
                                                }}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="productName" sm={3}><i className="far fa-circle"></i> 產品名稱</Label>
                                            <Col sm={9}>
                                                <AvField name="productName" type="text" id="productName" validate={{
                                                    required: {value: true, errorMessage: 'Please enter product name'},
                                                }}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="inferenceSpeed" sm={3}><i className="fas fa-tachometer-alt"></i> 預期偵測速度（每秒偵測圖片張數）</Label>
                                            <Col sm={9}>
                                                <AvField name="inferenceSpeed" type="number" id="inferenceSpeed" validate={{
                                                    required: {value: true, errorMessage: 'Please enter inference speed'},
                                                    number: {value: true, errorMessage: 'Invalid inference speed'},
                                                }}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="estimatedNumberOfProductionLines" sm={3}><i className="fas fa-grip-lines"></i> 估計產線數量</Label>
                                            <Col sm={9}>
                                                <AvField type="select" name="estimatedNumberOfProductionLines">
                                                    <option>5 - 10</option>
                                                    <option>10 - 20</option>
                                                    <option>20 - 50</option>
                                                    <option>50+</option>
                                                </AvField>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="expectedOverkillRate" sm={3}><i className="fas fa-percent"></i> 預期過殺率(%)</Label>
                                            <Col sm={9}>
                                                <AvField name="expectedOverkillRate" type="number" id="expectedOverkillRate" validate={{
                                                    required: {value: true, errorMessage: 'Please enter rate'},
                                                    number: {value: true, errorMessage: 'Invalid rate'},
                                                }}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="expectedUnderkillRate" sm={3}><i className="fas fa-percent"></i> 預期漏殺率(%)</Label>
                                            <Col sm={9}>
                                                <AvField name="expectedUnderkillRate" type="number" id="expectedUnderkillRate" validate={{
                                                    required: {value: true, errorMessage: 'Please enter rate'},
                                                    number: {value: true, errorMessage: 'Invalid rate'},
                                                }}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="notes" sm={3}><i className="far fa-sticky-note"></i> 註解</Label>
                                            <Col sm={9}>
                                                <AvField name="notes" type="text" id="notes"/>
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Col sm={12} className="text-right">
                                                <Button color="primary"><i className="fas fa-plus"></i> 送出</Button>{' '}
                                                <Link to='/products'>
                                                    <Button>取消</Button>
                                                </Link>
                                            </Col>
                                        </FormGroup>
                                    </AvForm>

                            </CardBlock>
                        </Card>
                    </ViewContent>
                </div>);
        }
    }
}

export default FormInputs;