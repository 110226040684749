const Constants = require('./Constants');

const isKapitoInternal = () => {
    return window.location.hostname.endsWith("kapito.fastable.ai");
    // return true;
};

const getCustomer = () => {
    let customer = window.location.hostname.split(".")[0];
    if (customer == "localhost") {
        console.warn("localhost; defaulting to internal");
        customer = "internal";
    }
    return customer.toLowerCase();
};

const getCustomerInfo = () => {
    const customer = getCustomer();

    let customerInfo = Constants.CUSTOMER_INFO[customer];

    if (isKapitoInternal()) {
        console.warn("kapito internal user auth requested");
        customerInfo.cognitoUserPoolId  = Constants.INTERNAL_INFO.cognitoUserPoolId;
        customerInfo.cognitoAppClientId = Constants.INTERNAL_INFO.cognitoAppClientId;
    }

    return customerInfo;
};

module.exports = Object.freeze({
    getCustomerInfo
});
