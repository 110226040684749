import React, { Component } from 'react';
import {
    Button, Card, CardBlock, CardGroup, CardTitle, Row, Input
} from 'reactstrap';
import Annotation from 'react-image-annotation';
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import {Table, Tr, Td} from 'reactable';

import './style.css';
import './annotation.scss'
import {Storage} from "aws-amplify";

const _ = require('lodash');

// UUID v4 (random)
const UUID = require('uuid/v4');

const Utils = require('../../shared/Utils');

class ViewHeader extends Component {
    render() {
        const {productId, datasetId, productName, zipFileName, filePath} = this.props;
        return (
            <div className="view-header">
                <header className="text-white">
                    <Link to={`/products/${productId}`} style={{color: 'white'}}>
                        <div className="inline-breadcrumb-item">{`${productName || ''}`}</div>
                    </Link>
                    <div className="inline-breadcrumb-item"> &gt; </div>
                    <Link to={`/products/${productId}/datasets/${datasetId}/images`} style={{color: 'white'}}>
                        <div className="inline-breadcrumb-item">{`${zipFileName || ''}`}</div>
                    </Link>
                    <div className="inline-breadcrumb-item"> &gt; </div>
                    <div className="inline-breadcrumb-item inline-breadcrumb-last">{`${filePath || ''}`}</div>
                    {/*<p className="mb-0 subtitle">Details</p>*/}
                </header>
            </div>
        );
    }
}

const ViewContent = ({children}) => (
    <div className="view-content view-components">
        {children}
    </div>
);

const Comments = styled.div`
  border: 1px solid black;
  max-height: 80px;
  overflow: auto;
`

const Comment = styled.div`
  padding: 8px;
  &:nth-child(even) {
    background: rgba(0, 0, 0, .05);
  }
  &:hover {
    background: #ececec;
  }
`
const resolution = Utils.getCustomerInfo()['resolution'];
const onlyPredefinedLabels = Utils.getCustomerInfo()['onlyPredefinedLabels'];
const defaultPredefinedLabels = ['OK', 'NG'];
const predefinedLabels = Utils.getCustomerInfo()['predefinedLabels'] || defaultPredefinedLabels;

class MyAnnotation extends Component {
    state = {
        activeAnnotations: [],
        annotations: [],
        newAnnotations: [],
        annotation: {},
        labels: this.props.labels,
        comments: '',
        skipped: false,
        imageUrl: null,
        imgHeight: null,
        imgWidth: null,
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            const labels = _.chain(this.props.annotations)
                .map((ann) => { return ann.data.text; })
                .concat(this.props.labels)
                .uniq()
                .value();

            this.setState(_.assign({}, this.state, {
                annotation: {},
                activeAnnotations: [],
                annotations: _.map(this.props.annotations, (annotation) => {
                    annotation.data.id = UUID();
                    return annotation;
                }),
                newAnnotations: [],
                labels,
                comments: '',
                skipped: false
            }));

            // fetch or other component tasks necessary for rendering
            this.fetch();
        }
    }

    saveAnnotations = () => {
        _.each(this.state.newAnnotations, (annotation) => {
            const {geometry, data} = annotation;
            const newData = _.assign({}, data);
            delete newData.id;
            const obj = {
                geometry,
                data: newData
            };
            const newAnnotation = _.mapValues(obj, JSON.stringify);
            newAnnotation.id = UUID();
            newAnnotation.productId = this.props.productId;
            newAnnotation.datasetId = this.props.datasetId;
            newAnnotation.imgId = this.props.imgUuid;

            const newRow = new this.props.AnnotationsModel(newAnnotation);
            newRow.save();
        });

        this.setState(_.assign({}, this.state, { newAnnotations: [] }));
    }

    onSubmit = (annotation) => {
        const { geometry, data } = annotation;

        const labels = _.chain(this.state.annotations)
            .concat(this.state.newAnnotations)
            .map((ann) => { return ann.data.text; })
            .concat(this.state.labels)
            .concat([data.text])
            .uniq()
            .value();

        this.setState(_.assign({}, this.state, {
            annotation: {},
            newAnnotations: _.concat(this.state.newAnnotations, {
                geometry,
                data: {
                    ...data,
                    id: UUID()
                }
            }),
            labels
        }));
    }

  onChange = (annotation) => {
    this.setState({ annotation });
  }

  removeAnnotation = (id) => e => {
      const toDelete = this.state.activeAnnotations[0];
      const annotations  = _.filter(this.state.annotations, (annotation) => {
          const ans = annotation.data.id == toDelete;
          if (ans)
              this.props.AnnotationsModel.delete(annotation.id);
          return !ans;
      });
      const newAnnotations = _.filter(this.state.newAnnotations, (annotation) => {
         return annotation.data.id != toDelete;
      });
      this.setState(_.assign({}, this.state, {
          annotations,
          newAnnotations
      }));
  }

  onMouseEnter = (id) => e => {
    this.setState({
      activeAnnotations: [
        id
      ]
    })
  }

  onMouseOut = (id) => e => {
    this.setState({
      activeAnnotations: [
      ]
    });
  }

  activeAnnotationComparator = (a, b) => {
    return a.data.id === b;
  }

  renderEditor = (props) => {
    const { geometry } = props.annotation;
    if (!geometry) return null;

    const f = (text) => (e) => {
      const { geometry } = props.annotation;
      const data = {
        ...props.annotation.data,
        text
      };

      const labels = _.chain(this.state.annotations)
       .concat(this.state.newAnnotations)
       .map((ann) => { return ann.data.text; })
       .concat(this.state.labels)
       .uniq()
       .value();

        this.setState(_.assign({}, this.state, {
            annotation: {},
            newAnnotations: _.concat(this.state.newAnnotations, {
                geometry,
                data: {
                    ...data,
                    id: UUID()
                }
            }),
            labels
        }));
    };

    const rows =
        _.map(this.state.labels, (label) => {
            return <tr key={`${label}-row`}><td key={`${label}-cell`} onClick={f(label)}>{label}</td></tr>;
        });


    // Image Geometry
    const baseImageWidth = this.refs.baseImage.el.clientWidth;
    const baseImageHeight = this.refs.baseImage.el.clientHeight;

    // Dropdown Menu Geometry
    // These magic numbers 266, 263 are the max width and the max height of the dropdown menu
    const menuWidth = 100 * (266 / baseImageWidth);  // percentage
    const menuHeight = 100 * (263 / baseImageHeight); // percentage

    let left, right, top, bottom;

    // Annotation Box Coordinates
    let ann_left, ann_right, ann_top, ann_bottom;
    ann_left = geometry.x;
    ann_right = geometry.x + geometry.width;
    ann_top = geometry.y;
    ann_bottom = geometry.y + geometry.height

    const hasEnoughBottomSpace = (ann_bottom + menuHeight) < 100;
    const hasEnoughTopSpace = (ann_top - menuHeight) > 0;
    const canAlignRight = (ann_right - menuWidth) > 0;
    const canAlignLeft = (ann_left + menuWidth) < 100;
    const canPlaceLeftTo = (ann_left - menuWidth) > 0;
    const canPlaceRightTo = (ann_right + menuWidth) < 100;

    // default - editor @ extending down, align right
    if(hasEnoughBottomSpace) {
      // The bottom of the annotation box has enough space to place the dropdown menu
      top = `${ann_bottom}%`;
      if (canAlignRight) {
        // aligning the right edge of the dropdown menu with the right edge of the annotation box fits
        right = `${100 - ann_right}%`;
      } else {
        // aligning the left edge of the dropdown menu with the left edge of the annotation box fits
        left = `${ann_left}%`;
      }
    } else if (hasEnoughTopSpace) {
      // The top of the annotation box has enough space to place the dropdown menu
      bottom = `${100 - ann_top}%`;
      if (canAlignRight) {
        // aligning the right edge of the dropdown menu with the right edge of the annotation box fits
        right = `${100 - ann_right}%`;
      } else {
        // aligning the left edge of the dropdown menu with the left edge of the annotation box fits
        left = `${ann_left}%`;
      }
    } else {
      // The dropdown menu needs to be placed between ann_top and ann_bottom
      bottom = `${100 - ann_bottom}%`;
      if (canPlaceLeftTo) {
        // placing the dropdown-menu left to the annotation box fits
        right = `${100 - ann_left}%`;
      } else if (canPlaceRightTo) {
        // placing the dropdown-menu right to the annotation box fits
        left = `${ann_right}%`;
      } else {
        // placing the dropdown-menu within the annotation box (partial occlusion)
        right = `${100 - ann_right}%`;
      }
    }


    return (
      <div 
        style={{
          background: 'white',
          borderRadius: 3,
          position: 'absolute',
          left,
          top,
          right,
          bottom
        }}
      >
        <div style={{padding:8, background:'#eee'}}>標籤選單</div>
        <div className="scrollable-dropdown-menu">
        <table className="table">
          <tbody>
            { rows }
          </tbody>
        </table>
        </div>
          <input
              onChange={e => props.onChange({
                  ...props.annotation,
                  data: {
                      ...props.annotation.data,
                      text: e.target.value
                  }
              })}
          />
          <button onClick={props.onSubmit}><i className="fas fa-plus"></i> 新增標籤</button>
      </div>
    )
  }

    componentDidMount() {
          this.fetch();
    }

    fetch() {
        const {datasetId} = this.props;

        // TODO - pagination
        this.props.ImagesModel.scan({datasetId}).all().exec().then((images) => {
            const pNewImages = _.chain(images)
                .sortBy(['filePath'])
                .map(async (i) => {
                    i.url = await Storage.get(i.fullPath, {
                        level: 'public',
                        customPrefix: {
                            public: '',
                        }
                    });
                    return i;
                })
                .value();

            // TODO - this is slow because we wait for signed URLs for all images
            Promise.all(pNewImages).then((newImages) => {
                const newState = _.assign({}, this.state, {tableData: newImages});
                this.setState(newState);
            });
        }).catch((e) => {
            console.error(e);
        });

        if (this.props.imgUuid) {
            this.props.CommentsModel.get(this.props.imgUuid).then((comment) => {
                if (!comment)
                    return;

                const newState = _.assign({}, this.state, {comments: comment.text, skipped: comment.skipped});
                this.setState(newState);
            }).catch((e) => {
                console.error(e);
            });
        }
    }
    getImageLength(imageUrl) {
      const image = new Image();
      const setImageLength = (event) => {
        this.setState({
          imgWidth: parseInt(event.target.width * resolution),
          imgHeight: parseInt(event.target.height * resolution),
          imageUrl: imageUrl
        });
      };
      image.src = imageUrl;
      image.onload = setImageLength;
    }
  render () {
    const { imgWidth, imgHeight } = this.state;
    const numImages = _.size(this.state.tableData);

    if (this.props.imgId >= numImages)
        return <Row/>;

    // TODO - rethink how prev/next navigation should work, since DynamoDB scan() ordering is not consistent
    const imageUrl = this.state.tableData[this.props.imgId]['url'];
    if (resolution && this.state.imageUrl != imageUrl) {
      this.getImageLength(imageUrl);
    }
    const saveAll = (e) => {
        this.saveAnnotations(this.state.annotations);
        this.setState(_.assign({}, this.state, {
            annotations: _.concat(this.state.annotations, this.state.newAnnotations),
            newAnnotations: []
        }));

        const newRow = new this.props.CommentsModel({
            id: this.props.imgUuid,
            text: this.state.comments,
            skipped: this.state.skipped,
            created: (new Date).getTime()
        });
        newRow.save();
    };

      const isTouched = !_.every([
          _.isEmpty(this.state.comments),
          _.chain(this.state.annotations).concat(this.state.newAnnotations).isEmpty().value()
      ]);

      const prevLinkTo = this.props.imgId == 0 ? '#' : `/products/${this.props.productId}/datasets/${this.props.datasetId}/images/${this.props.imgId-1}`;
      const nextLinkTo = this.props.imgId == numImages-1 ? '#' : `/products/${this.props.productId}/datasets/${this.props.datasetId}/images/${this.props.imgId+1}`;
   
      const View=           
      <Annotation
        ref="baseImage"
        src={imageUrl}
        annotations={_.concat(
          this.state.annotations,
          this.state.newAnnotations
        )}
        activeAnnotationComparator={this.activeAnnotationComparator}
        activeAnnotations={this.state.activeAnnotations}
        renderEditor={this.renderEditor}
        type={this.state.type}
        value={this.state.annotation}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
    />   


      const ResolutionView=  
        <div className="rate-container">
         <div className="container-horizontal">
            <div className="rate-horizontal">
              <div className="arrow-horizontal">
                <div className="point-left"></div>
                <div className="line-horizontal"></div>
              </div>
              <div className="value-horizontal">{imgWidth}mm</div>
              <div className="arrow-horizontal-2">
                <div className="line-horizontal"></div>
                <div className="point-right"></div>
              </div>
            </div>
         <div className="mt-2 rate-container">
          <div className="container-vertical">
            <div
              className="rate-straight mr-4"
            >
              <div className="arrow-straight">
                <div className="point-up"></div>
                <div className="line-straight"></div>
              </div>
              <div className="value-straight">{imgHeight}mm</div>
              <div className="arrow-straight-2">
                <div className="line-straight"></div>
                <div className="point-down"></div>
              </div>
            </div>
          </div>
          <div className="rate-container">
            {View}
          </div>
        </div>
      </div>
    </div>
      return (
      <Row>
        <Card className="col-md-2">
          <CardBlock>
              <CardTitle tag="h6" className="text-uppercase">上一張 / 下一張</CardTitle>
              <Link to={ prevLinkTo }>
                <Button color="primary" disabled={ prevLinkTo == '#' } className="mb-2 mr-4" onClick={ saveAll }><i className="fas fa-angle-left"></i></Button>{" "}
              </Link>
              <Link to={ nextLinkTo }>
                <Button color="primary" disabled={ nextLinkTo == '#' } className="mb-2" onClick={ saveAll }><i className="fas fa-angle-right"></i></Button>{" "}
              </Link>
              <div className="d-flex align-items-center">
                  <Input className="skip-check-box" type="checkbox" disabled={isTouched} checked={!isTouched && this.state.skipped} onChange={e => this.setState(_.assign({}, this.state, {skipped: e.target.checked}))}/>
                  <span> 略過此圖片</span>
              </div>
          </CardBlock>
          <CardBlock>
              <CardTitle tag="h6" className="text-uppercase"><i className="far fa-comment-alt"></i> 註解</CardTitle>
              <div className="d-flex align-items-center comment-box">
                <Input type="textarea" value={this.state.comments} onChange={e => this.setState(_.assign({}, this.state, {comments: e.target.value}))}/>
              </div>
              <div className="d-flex align-items-center">
                  <Button color="primary" className="mb-2" onClick={ saveAll }><i className="far fa-save"> 儲存</i></Button>{" "}
              </div>
          </CardBlock>
          <CardBlock>
              <CardTitle tag="h6" className="text-uppercase"><i className="fas fa-vector-square"></i> 標籤 </CardTitle>
              <div className="d-flex align-items-center">
                <table className="table">
                    <tbody>
                    {_.chain(this.state.annotations).concat(this.state.newAnnotations).map((annotation) => {
                        return (
                          <tr key={UUID()}>
                            <td
                              key={annotation.data.id}
                              onMouseEnter={this.onMouseEnter(annotation.data.id)}
                              onMouseOut={this.onMouseOut(annotation.data.id)}
                            >
                              {annotation.data.text}
                            </td>
                            <td
                                onMouseEnter={this.onMouseEnter(annotation.data.id)}
                                onMouseOut={this.onMouseOut(annotation.data.id)}
                            >
                              <Button
                                  color="primary"
                                  className="mb-2 far fa-trash-alt"
                                  onMouseEnter={this.onMouseEnter(annotation.data.id)}
                                  onMouseOut={this.onMouseOut(annotation.data.id)}
                                  onClick={ this.removeAnnotation(annotation.data.id) }>
                              </Button>
                            </td>
                          </tr>);
                      }).value()
                    }
                    </tbody>
                </table>
              </div>
          </CardBlock>
        </Card>
        <Card className="col-md-10">
          <CardBlock>
            {resolution? ResolutionView:View}
          </CardBlock>
        </Card>
      </Row>
    );
  }
}

export default class Wrapped extends Component {
  state = {
      labels: predefinedLabels
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props, prevProps)) {
        // fetch or other component tasks necessary for rendering
        this.fetch();
    }
  }

  componentDidMount() {
      this.fetch();
  }

  fetch() {
      const {productId, datasetId, imgId} = this.props.match.params;

      this.props.ProductsModel.get(productId).then((product) => {
          this.setState(_.assign({}, this.state, {productName: product.name}));
      }).catch((e) => {
          console.error(e);
      });


      if(!onlyPredefinedLabels) {
          this.props.AnnotationsModel.scan({
            productId,
          }).all().exec().then((annotations) => {
              const labels = _.chain(annotations)
                  .map((ann) => {
                      const parsed = JSON.parse(ann.data)
                      return parsed.text;
                  })
                  .concat(predefinedLabels)
                  .uniq()
                  .value();

              this.setState(_.assign({}, this.state, { labels }));
          });
      }


      // TODO - fix indexing
      this.props.ImagesModel.scan({datasetId}).all().exec().then((images) => {
          const sorted = _.chain(images)
              .sortBy(['filePath'])
              .value();

          const image = sorted[imgId];

          // TODO - pagination
          this.props.AnnotationsModel.scan({
              productId,
              datasetId,
              imgId: image.id
          }).all().exec().then((annotations) => {
              const newAnnotations = _.chain(annotations)
                  .sortBy(['created'])
                  .map((annotation) => {
                      annotation.data = JSON.parse(annotation.data);
                      annotation.geometry = JSON.parse(annotation.geometry);
                      return annotation;
                  })
                  .value();

              this.setState(_.assign({}, this.state, {
                  zipFileName: image.zipFileName,
                  filePath: image.filePath,
                  imgUuid: image.id,
                  annotations: newAnnotations,
              }));
          }).catch((e) => {
              console.error(e);
          });
      }).catch((e) => {
          console.error(e);
      });
  }

  render () {
    const {productId, datasetId, imgId} = this.props.match.params;

    const {productName, zipFileName, filePath, imgUuid, annotations} = this.state;

    const imgIdInt = imgId ? Number(imgId) : 0;

    return (
      <div className="view">
          <ViewHeader productId={productId} datasetId={datasetId} productName={productName} zipFileName={zipFileName} filePath={filePath}/>
          <ViewContent>
              <Card className="mb-4">
                  <CardBlock>
                      <MyAnnotation productId={productId} datasetId={datasetId} imgId={imgIdInt} imgUuid={imgUuid} labels={this.state.labels} annotations={_.isEmpty(annotations) ? [] : annotations}
                                    AnnotationsModel={this.props.AnnotationsModel}
                                    ImagesModel={this.props.ImagesModel}
                                    CommentsModel={this.props.CommentsModel}
                      />
                  </CardBlock>
              </Card>
          </ViewContent>
      </div>
    );
  }
};
