import React, { Component } from 'react';
import {Link} from 'react-router';
import {
    Form, Input, Label, FormGroup, Button, FormText,
    Card, CardBlock
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import IconTwitter from 'react-icons/lib/fa/twitter';
import IconFacebook from 'react-icons/lib/fa/facebook';
import IconGoogle from 'react-icons/lib/fa/google';
import '../style.css';

import { Auth } from "aws-amplify";

const logoUrl = "https://s3-ap-northeast-1.amazonaws.com/www.fastable.ai/img/fastable_logo_dark_transparent_bg.png";
const logoStyle = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%"
};

export default class SignIn extends Component {
    state = {
        isPasswordChange: false,
        user: null
    };

    onValidSubmit(event, values) {
        event.preventDefault();

        if (!this.state.isPasswordChange) {
            const {username, password} = values;
            Auth.signIn(username, password).then((user) => {
                // console.warn('user', user);
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    this.setState({
                        isPasswordChange: true,
                        user
                    });
                } else {
                    this.props.userHasAuthenticated(true).then(() => {
                        this.props.history.push("/");
                    });
                }
            }).catch((e) => {
                this.props.userHasAuthenticated(false);
                this.setState({
                    isPasswordChange: false,
                    user: null
                });
                console.error(e);
                alert(e.message);
            });
        } else {
            // console.warn('this.state.user', this.state.user);
            const {newPassword} = values;
            Auth.completeNewPassword(this.state.user, newPassword)
                .then(user => {
                    this.props.userHasAuthenticated(true).then(() => {
                        this.props.history.push("/");
                    });
                })
                .catch((e) => {
                    this.props.userHasAuthenticated(false);
                    this.setState({
                        isPasswordChange: false,
                        user: null
                    });
                    console.error(e);
                    alert(e.message);
                });

        }
    }

    handleInvalidSubmit(event, errors, values) {
    }

    render() {
        return (
            <div className="view">
                <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
                    <Card className="mb-3 form-card">
                        <CardBlock>
                            <header className="mb-5">
                                <img
                                    src={ logoUrl }
                                    style={ logoStyle }
                                />
                                {/*
                                <Link to="/">
                                    <svg width="32px" height="44px" viewBox="11 6 50 42" style={{'marginLeft': '-4px'}} version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <path id="Path" stroke="#2962FF" strokeWidth="12" fill="none" d="M26.5282909,38.9526768 C26.5282909,38.9526768 49.3408202,31.7856836 49.3408202,28.3647852 C49.3408202,24.9438868 49.5702829,11.7001695 37.0898141,17.411107 C24.6093454,23.1220444 24.821289,23.6064453 24.821289,23.6064453 C24.821289,23.6064453 22.8105177,47.2876359 26.528291,53.5093155 C30.2460643,59.7309951 52.7998045,53.5093155 54.7998045,53.5093155"></path>
                                    </svg>
                                    <strong className="h3 text-uppercase" style={{color: '#212121'}}>ract</strong>
                                </Link>
                                */}
                                <p className="lead" style={{ textAlign: "center" }}>Sign In to continue</p>
                            </header>
                            <AvForm onValidSubmit={this.onValidSubmit.bind(this)} onInvalidSubmit={this.handleInvalidSubmit.bind(this)}>
                                {/*
                                <FormGroup className="mb-4">
                                    <Label>Email Address</Label>
                                    <Input type="email" placeholder="someone@xyz.com"/>
                                </FormGroup>
                                */}
                                <FormGroup className="mb-4">
                                    <Label>Username</Label>
                                    {/*<Input type="text"/>*/}
                                    <AvField name="username" type="text" id="username" validate={{
                                        required: {value: true, errorMessage: 'Please enter username'},
                                    }} readOnly={this.state.isPasswordChange}/>
                                </FormGroup>
                                {!this.state.isPasswordChange &&
                                <FormGroup className="mb-4">
                                    <Label>Password</Label>
                                    {/*<Input type="password"/>*/}
                                    <AvField name="password" type="password" id="password" validate={{
                                        required: {value: true, errorMessage: 'Please enter password'},
                                    }}/>
                                    {/*<FormText className="text-right"><Link to="/pages/forget">Forget your password?</Link></FormText>*/}
                                </FormGroup>}
                                {this.state.isPasswordChange &&
                                <FormGroup className="mb-4">
                                    <Label>New Password</Label>
                                    {/*<Input type="password"/>*/}
                                    <AvField name="newPassword" type="password" id="newPassword" validate={{
                                        required: {value: true, errorMessage: 'Please enter new password'},
                                    }}/>
                                    {/*<FormText className="text-right"><Link to="/pages/forget">Forget your password?</Link></FormText>*/}
                                </FormGroup>
                                }
                                <FormGroup className="text-right">
                                    {/*<Link className="btn btn-success" to="/pages/register">Register</Link>{" "}*/}
                                    <Button color="primary">Sign In</Button>
                                </FormGroup>
                            </AvForm>
                        </CardBlock>
                    </Card>
                    {/*
                    <p className="font-weight-bold">or sign in with...</p>
                    <ul className="list-unstyled social-auth">
                        <li><a href="#na"><IconTwitter size={22}/></a></li>
                        <li><a href="#na"><IconFacebook size={22}/></a></li>
                        <li><a href="#na"><IconGoogle size={22}/></a></li>
                    </ul>
                    */}
                </div>
            </div>
        );
    }
}
